.paginator-container{
    display: flex;
    gap: 15px;
    display: flex;
    justify-content: space-evenly
}

/* Page Navigator */
.paginator-container-bold{
    font-weight: bold;
}

.paginator-selector-container{
    margin-left: 0;
    width: calc(100% - -8px);
    /* margin-top: 2px; */
    position: relative;
    width: 0px;
    height: 0px;
}

.paginator-selector__control {
    border: none !important;
    font-size: 15px !important;
    text-align: center !important;
    min-width: 34px !important;
    background-color: var(--color-menu) !important;
    padding-top: 0 !important;
    padding-bottom: 0px !important;
    margin-top: 0 !important;
    border-radius: 11px !important;
    width: 34px !important;
    outline: none !important;
    box-shadow: none !important;
}

.paginator-selector__menu, .paginator-selector__menu-list {
    border: none !important;
    font-size: 15px !important;
    text-align: center !important;
    min-width: 34px !important;
    background-color: var(--color-menu) !important;
    padding-top: 0 !important;
    padding-bottom: 0px !important;
    margin-top: 0 !important;
    border-radius: 11px !important;
    width: 34px !important;
}

.paginator-selector__option {
    height: 28px !important;
    line-height: 28px !important;
    padding: 0 2px !important;
    overflow: hidden !important;
    color: var(--color-primary-dark) !important;
}

.paginator-selector__option:hover, .paginator-selector__option--is-focused {
    background-color: var(--color-border) !important;
    color: var(--color-text) !important;
    color: var(--color-accent) !important;
    cursor: pointer;
}

.paginator-selector__option--is-selected {
    background-color: var(--color-border) !important;
}

.paginator-selector__menu-list::-webkit-scrollbar {
    width: 0px;
}

.paginator-selector__menu-list::-webkit-scrollbar-thumb {
    background: var(--color-scrollbar);
    border-radius: 3px;
    cursor: pointer; 
}

.paginator-selector__indicator {
    opacity: 0.65;
    padding: 0 !important;
    width: 0px !important;
    height: 0px !important;
    margin-bottom: 0px !important;
}

.paginator-selector__indicator-separator {
    background-color: transparent !important;
    display: none !important;
}

.paginator-dropdown-top {
    position: absolute;
    bottom: 390%; /* Positions the dropdown above the pagination */
    left: 0;
    z-index: 10;
}