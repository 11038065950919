.attach-info-card{
    white-space: nowrap;
    width: 100%; 
    height: 56px; 
    margin-bottom: 10px; 
    border-radius: 6px;
    text-align: left;
    transition: all 0s; 
    transition: background-color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
}

.attach-info-card-detail{
    display: inline-block;
    line-height: 56px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
}

.attach-info-card-detail.columnA{
    margin-left: 23px;
    padding-right: 20px; 
}
.attach-info-card-detail.columnB{
    padding-right: 20px; 
}
.attach-info-card-detail.columnC{
    padding-right: 20px;
}
.attach-info-card-detail.columnD{
    margin-right: 20px;
}
.attach-info-card-detail.edit{
    background-image: url('/images/icons/edit.png');
    background-size: 14px;
    width: 50px; 
    height: 56px; 
    background-position-y: center;
    background-position-x: 0; 
    padding-left: 20px;
    background-repeat: no-repeat;
}

.attach-info-card-detail.cancel{
    background-image: url('/images/icons/delete.png');
    background-size: 14px;
    width: 50px; 
    height: 56px; 
    background-position-y: center;
    background-position-x: 0; 
    padding-left: 20px;
    background-repeat: no-repeat;
    color: var(--color-accent-dark);
}



.attach-info-card:hover{
    cursor: pointer;
    background-color: var(--color-hover-04);
}

.attach-info-card.selected{
    background-color: var(--color-accent-05);
    border: 1px solid var(--color-accent-75);
}

.back-button{
    
}