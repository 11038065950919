.warning-container{
    width: 100%; 
    min-height: 40px;
    height: auto; 
    background-color: var(--color-panel);
    border-radius: 15px;
    margin-bottom: 20px;
}

.warning-content{
    padding: 20px;
}

.warning-title{
    font-weight: bold;
    background-image: url('/images/icons/error.png');
    background-repeat: no-repeat;
    background-size: 25px;
    background-position: left center; 
    padding-left: 35px;
    display: flex;
    align-items: center;
    font-size: 18px
}
.warning-message{
    padding-left: 35px;
    font-size: 13px;
}