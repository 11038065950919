.listable-container {
    width: 100%;
    height: 100%; /* Adjust this as needed to ensure it's appropriate for your layout */
}

.listable-headers {
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-border);
    z-index: 9999;
    background-color: var(--color-background); 
}

.listable-header{
    flex-grow: 1; 
    color: var(--color-text-light); 
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
}

.listable-header.sortable:hover{
    color: var(--color-accent); 
    cursor: pointer;
}

.listable-rows-container{
}

.listable-row{
    width: 100%; 
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-border-light);
}

.listable-row.pinned:not(:has(~ .listable-row.pinned)) {
    border-bottom: 2px solid var(--color-primary);
}

.listable-row.selected{
    background-color: var(--color-menu-raised);
}

.listable-row.disabled{
    opacity: 0.5;
    cursor: not-allowed;
}

.listable-cell{
    flex-grow: 1; 
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden; 
    line-height: 38px;
    padding-left: 10px;
    padding-right: 10px;
}

.listable-loading{
    background: linear-gradient(270deg, var(--color-panel), var(--color-background-raised), var(--color-panel));
    background-size: 200% 200%;
    animation: loadingAnimation 3s ease infinite;
    border-radius: 3px;
}

@keyframes loadingAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }