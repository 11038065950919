.card{
    /* margin-right: 10px;  */
    border: 2px solid transparent;
    border-radius: 5px;
    padding-left: 3px;
    padding-right: 3px;
    font-size: 18px;
    font-weight: 600;
    height: 22px;
    line-height: 24px; 
    cursor: pointer;
    text-align: center;
}

.card.span{
    justify-content: flex-end;
    text-align: right;
}

.card.span.todo{
    border: 2px solid var(--color-accent)
}

.card.span.missing{
    border: 2px solid var(--palette-color-1)
}

.card.span.onTrack{
    border: 2px solid var(--palette-color-3)
}

.card.span.complete{
    border: 2px solid var(--palette-color-3)
}

.card.na{
    color: gray; 
    cursor: not-allowed;
}

.card.todo{
    background-color: var(--color-accent); 
    color: white; 
}

.card.missing{
    background-color: var(--palette-color-1);
    color: white; 
}

.card.onTrack{
    color: var(--palette-color-3); 
}

.card.complete{
    color: var(--palette-color-3); 
}

.card.flagged{
    border-bottom: 2px solid var(--color-accent);
}