.timeline-container {
    padding-right: 40px;
}

.timeline {
    padding-top: 60px;
}

.timeline-event {
    position: relative;
    cursor: pointer;
}

.timeline-event-icon {
    position: absolute;
    top: 0;
    left: 145px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--color-border);
    transition: all 0.2s ease-in-out;
}

.timeline-event:hover .timeline-event-icon {
    background-color: var(--color-primary);
}

.timeline-event-date {
    position: absolute;
    top: 0;
    left: 0;
    width: 130px;
    text-align: right;
    text-transform: uppercase;
    font-size: 15px;
    line-height: 22px;
    font-weight: 600;
}

.timeline-event-weekday {
    position: absolute;
    top: 22px;
    left: 0;
    width: 130px;
    text-align: right;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: var(--color-text-light);
}

.timeline-event-details {
    margin-left: 158px;
    padding-left: 40px;
    border-left: 4px solid var(--color-border);
    margin-right: 360px;
    padding-bottom: 50px;
}

.timeline-event-details h1 {
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 10px;
}

.timeline-event-details p {
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
    padding-bottom: 10px;
}

.timeline-event-details p strong {
    font-size: 14px;
    color: var(--color-text) !important;
}

.timeline-event-details ul {
    margin-top: -10px;
    margin-bottom: 10px;
}

.timeline-event-details ul li {
    font-size: 14px;
    margin-left: 20px;
    color: var(--color-text) !important;
}

/* .timeline-event:hover .timeline-event-details * {
    color: var(--color-text) !important;
} */

.timeline-event-physician {
    position: absolute;
    top: 0;
    right: 0;
    width: 320px;
    padding-left: 40px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
}

.timeline-event-facility {
    position: absolute;
    top: 24px;
    right: 0;
    width: 320px;
    padding-left: 40px;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text-light) !important;
}

.timeline-confidence-low {
    width: 8px;
    height: 8px;
    display: inline-block;
    margin-left: -14px;
    margin-right: 6px;
    margin-bottom: 0;
    border-radius: 50%;
    background-color: rgb(220,0,0);
}

.timeline-confidence-medium {
    width: 8px;
    height: 8px;
    display: inline-block;
    margin-left: -14px;
    margin-right: 6px;
    margin-bottom: 0;
    border-radius: 50%;
    background-color: rgb(220,180,0);
}

.timeline-years, .timeline-matches {
    position: fixed;
    right: 40px;
    width: 40px;
    top: 135px;
    bottom: 15px;
}

.timeline-matches {
    right: 80px;
    width: 10px;
    bottom: 5px;
}

.timeline-year-anchor, .timeline-match-anchor {
    width: 100%;
    position: absolute;
    color: var(--color-primary-dark);
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    height: 14px;
    text-align: right;
    border-top: 2px solid var(--color-primary);
}

.timeline-match-anchor {
    border-top: 2px solid var(--color-accent);
    height: 0;
}

.timeline-highlight {
    background-color: var(--color-accent-20) !important;
    border-bottom: 2px solid var(--color-accent) !important;
}

.timeline-highlight-active {
    background-color: var(--color-primary-20) !important;
    border-bottom: 2px solid var(--color-primary) !important;
}

.timeline-match-counter {
    right: 375px;
    top: 30px;
}

.timeline-doc-results {
    position: absolute;
    right: 100px;
    top: 70px;
    width: 220px;
    font-size: 14px;
    line-height: 30px;
    color: var(--color-primary);
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    z-index: 5000;
}

.timeline-doc-results:hover {
    color: var(--color-accent);
}

.title-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 70px;
    margin-left: 226px;
    margin-right: 39px;
    gap: 30px;
    border-bottom: 1px solid var(--color-border);
}

.timeline-title {
    height: 60px;
    line-height: 60px;
    font-size: 26px;
    animation: fadein 0.3s ease-in-out 0s 1 !important;
    transition: all 0.15s ease-in-out;
}

.timeline-doc-sort {
    margin-top: 6px;
    font-size: 15px;
    color: var(--color-primary-dark);
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.timeline-doc-sort:hover {
    color: var(--color-accent);
    text-decoration-line: underline ;
}