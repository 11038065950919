.investments-status-stat-container{
    display: flex;
    align-items: center;
    position: relative;
}

.investment-status-chart-container{
    /* background-color: red; */
    width: 400px;
    margin-left: 20px;
}

.status-balance-mismatch{
    background-color: var(--palette-color-1); 
    width: 2px; 
    height: 30px; 
    margin: 0 2px; 
}

.listable-status-column{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden; 
}