.ta-spreadsheet{
    border-collapse: collapse;
    overflow-x: hidden;
    width: 100%; 
    table-layout: fixed;
}

.ta-spreadsheet-loading{
    width: 100%; 
    height: 500px; 
    background-color: var(--color-menu-raised);
    display: flex;
    justify-content: center;
    align-items: center;
}

.spreadsheet-header{
    font-size: 14px;
    width: 170px;
}
.spreadsheet-header-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    white-space: nowrap;
    align-items: flex-end;
}

.spreadsheet-header-value{
    padding-left: 20px;
    padding-right: 20px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 27px; 
}

.spreadsheet-header-action{
    padding-right: 20px;
    padding-left: 20px;
    width: fit-content;
}


.spreadsheet-row{
    height: 35px;
}

.spreadsheet-cell{
    float: left; 
    height: auto; 
    padding-left: 20px;
    padding-right: 20px;
    white-space: nowrap;
    background-repeat: no-repeat;
    background-position: right;
    background-size: 15px;
}

.spreadsheet-row > td:first-child .spreadsheet-cell{
    padding-left: 10px;
}


.icon-open{
    background-image: url('/images/icons/action-open.png');
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: right 4px;
    content: "Open File"
}

.icon-warn{
    background-image: url('/images/icons/alert.png');
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: right 3px;
}

.icon-approve{
    background-image: url('/images/icons/confirm.png');
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: right 4px;
    content: "Approve"
}

.icon-calculated{
    background-image: url('/images/icons/calculated.png');
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: right 3px;
}

.column-error{
    color: var(--palette-color-1); 
    font-weight: 600;
}

.column-warning{
    color: var(--color-accent-dark);
    font-weight: 600;
}

.column-na{
    color: var(--color-text-light); 
    font-weight: 600;
}

.column-confirmed{
    background-image: url('/images/icons/reviewed.png'), url('/images/icons/action-open.png');
    background-size: 15px, 16px;
    background-repeat: no-repeat, no-repeat;
    background-position: left 3px, right 3px;
}

.spreadsheet-navigation-container{
    position: absolute;
    width: 85px;
    z-index: 2;
    height: 20px; 
    top: 0;
    display: flex;
}

.spreadsheet-navigation-container.left{
    left: 80px;
    align-items: center;
    justify-content: end;
}

.spreadsheet-navigation-container.right{
    right: 80px;
    align-items: center;
    justify-content: flex-start;
}

.spreadsheet-navigation-start{
    margin-left: auto; 
    width: 20px;
    height: 20px;
    background-image: url('/images/icons/multiple-previous.svg');
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.spreadsheet-navigation-back{
    margin-left: auto; 
    width: 20px;
    height: 20px;
    background-image: url('/images/icons/prev-arrow.svg');
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.spreadsheet-navigation-next{
    margin-right: auto; 
    width: 20px;
    height: 20px;
    background-image: url('/images/icons/next-arrow.svg');
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.spreadsheet-navigation-end{
    margin-right: auto; 
    width: 20px;
    height: 20px;
    background-image: url('/images/icons/multiple-next.svg');
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.spreadsheet-navigation-issue-count{
    width: 20px;
    height: 20px;
    background-color: var(--color-accent);
    font-size: 14px; 
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: white; 
    border-radius: 50%;
    margin-left: 10px; 
    margin-right: 10px; 
}



.cell-error-low{
}

.cell-error-high{
    font-weight: 600; 
    color: var(--color-accent-dark); 
}

.cell-context-menu{
    position: absolute; 
    top: 100%; 
    right: 0; 
    width: 240px; 
    height: auto;
    background-color: var(--color-menu-raised); 
    z-index: 9999;
    border-radius: 10px;
    z-index: 99999;
    overflow: hidden;
    white-space: wrap;
}

.cell-error-title{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
    line-height: 16px; 
    padding: 15px;
    padding-bottom: 0; 
}

.cell-error-description{
    font-size: 12px;
    line-height: 16px;
    padding-left: 15px;
    padding-right: 15px; 
}

.cell-error-action-container{
    margin-top: 8px; 
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative; 
    gap: 4px 30px; 
    width: 100%; 
    padding-bottom: 15px;
}

.cell-error-action{
    display: flex;
    align-items: center;
}

.cell-action-filter{
    position: relative; 
    background-color: var(--color-menu-raised); 
    min-width: 225px; 
    height: 36px; 
    font-size: 14px; 
    font-weight: 500;
    display: flex;
    align-items: center;
    padding-left: 15px; 
    font-size: 14px;
    font-weight: 500;
    margin-top: 0; 
}
.cell-action-filter:hover{
    background-color: var(--color-hover); 
}

.cell-action-accept{
    padding-left: 20px; 
    background-image: url('/images/icons/action-check.png');
    background-size: 16px; 
    background-repeat: no-repeat;
    background-position: center left;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-left: 15px;
}

.cell-action-review{
    padding-left: 20px; 
    background-image: url('/images/icons/action-open.png');
    background-size: 16px; 
    background-repeat: no-repeat;
    background-position: center left;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-left: 15px; 
}