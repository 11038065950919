.colored-tag{
    display: flex;
    align-items: center;
    border-radius: 4px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 900;
    font-family: 'Ricardo';
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    width: fit-content;
    height: 16px;
    position: relative;
}