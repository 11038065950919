.investments-transactions-selector-container {
    margin-left: 0;
    width: calc(100% - 15px);
    margin-top: 2px;
    position: relative;
}

.investments-transactions-edit {
    padding-left: 20px;
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: left center;
    width: fit-content;
}

.investments-transactions-select-edit {
    width: calc(100% - 40px) !important;
    margin: 0 !important;
    padding: 10px 13px !important;
    height: 16px !important;
}

.investments-transactions-input-container {
    width: 100px !important;
}

.investments-transactions-select-container {
    margin-left: 0;
    width: calc(100% - 40px);
    margin-top: 2px;
    position: relative;
}


/* .filter-select__option {
    height: 28px !important;
    line-height: 28px !important;
    padding: 0 10px !important;
    overflow: hidden !important;
} */

/* .filter-select__menu, .filter-select__menu-list {
    border: none !important;
    font-size: 16px !important;
    text-align: left !important;
    min-width: 176px !important;
    background-color: var(--color-menu) !important;
}

.filter-select__menu-list::-webkit-scrollbar {
    width: 6px;
    overflow: hidden !important;
}

.filter-select__menu-list::-webkit-scrollbar-track {
    background: rgba(120,120,120,0.5);
}

.filter-select__menu-list::-webkit-scrollbar-thumb {
    background: var(--color-scrollbar);
    border-radius: 3px;
    cursor: pointer; 
} */