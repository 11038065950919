.investment-summary-plan-list * .stack-list-header-left {
    margin-left: 0 !important;
    padding-left: 3px !important;
}

.investment-summary-plan-list .stack-list-header {
    margin: 0 !important;
}

.investment-summary-plan-list .stack-list-left {
    /* width: calc(100% - 143px);
     */
     display: inline-block;
     /* background-color: red; */
     width: 100%;
}

.investment-summary-plan-list .stack-list-header {
    display: flex;
}
