.filter-plans-container {
    display: flex;
    align-items: flex-end;
    width: 110px;
    margin-left: 30px;
    font-weight: 500px;
    /* text-decoration: underline; */
}

.filter-plans-container:hover {
    text-decoration: underline;
    cursor: pointer;
}


.filter-plans-back {
    position: fixed;
    left: 32px;
    top: 30px;
    z-index: 3000;
}

.filter-plans-discard {
    /* left: 211px; */
    background-image: url('/images/icons/back.png');
    background-position: left;
    background-repeat: no-repeat;
    background-size: 16px;
    padding-left: 20px;
    cursor: pointer;
}

.filter-plans-discard:hover {
    text-decoration: underline;
    cursor: pointer;
    color: var(--color-accent);
}


.modal-content .filter-plans-title.override {
    margin-bottom: 10px !important;
}

.filter-plans-clear {
    color: var(--color-primary-dark);
    transition: all 0.1s ease-in-out;
    font-size: 14px;
}

.filter-plans-clear:hover {
    text-decoration: underline;
    cursor: pointer;
    color: var(--color-accent);
}

.filter-select-container{
    margin-left: 0;
    width: calc(100% - 10px);
    margin-top: 2px;
    position: relative;
}


.filter-select__control {
    height: 36px !important;
    min-height: 36px !important;
    max-height: 36px;
    border: none !important;
    border-radius: 10px !important;
    padding: 0 5px ;
    background-color: var(--color-background-input) !important;
    display: flex;
    align-items: center;
}

.filter-select__control input {
    height: 22px !important;
    font-size: 16px !important;
}

.filter-select__input {
    height: 22px !important;
    font-size: 16px !important;
    color: var(--color-text-light) !important;
}

.filter-select__value-container {
    width: 100%;
}

.filter-select__control:hover {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.filter-select__control--is-focused {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.filter-select__placeholder {
    color: var(--color-scrollbar) !important;
}

.filter-select__indicator {
    opacity: 0.65;
    padding: 0 !important;
    width: 14px !important;
    height: 14px !important;
    margin-bottom: 6px !important;
}

.filter-select__indicator-separator {
    background-color: transparent !important;
    display: none !important;
}

.filter-select__single-value {
    height: 16px !important;
    line-height: 16px !important;
    font-size: 16px !important;
    color: var(--color-text) !important;
    margin-left: 0 !important;
    margin-right: 4px !important;
}

.filter-select__single-value > div {
    height: 16px !important;
    line-height: 16px !important;
}

.filter-select__value-container, .filter-select__control input {
    box-shadow: none !important;
    border: none !important;
    margin: 0 !important;
}

.filter-select__menu, .filter-select__menu-list {
    border: none !important;
    font-size: 16px !important;
    text-align: left !important;
    min-width: 176px !important;
    background-color: var(--color-menu) !important;
}

.filter-select__option {
    height: 28px !important;
    line-height: 28px !important;
    padding: 0 10px !important;
    overflow: hidden !important;
}

.filter-select__option:hover, .filter-select__option--is-focused {
    background-color: var(--color-border) !important;
    color: var(--color-text) !important;
}

.filter-select__option--is-selected {
    background-color: var(--color-primary) !important;
    color: var(--color-text) !important;
}

.filter-select__menu-list::-webkit-scrollbar {
    width: 6px;
}

.filter-select__menu-list::-webkit-scrollbar-track {
    background: rgba(120,120,120,0.5);
}

.filter-select__menu-list::-webkit-scrollbar-thumb {
    background: var(--color-scrollbar);
    border-radius: 3px;
    cursor: pointer; 
}

.filter-inline-submit {
    width: 150px !important;
    float: none !important;
    margin-top: 0px !important;
}

.filter-container-validation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 100px;
}

.title-icon:hover {
    text-decoration: underline;
    cursor: pointer;
    color: var(--color-accent);
}

.filter-editor-card {
    display: block;
    height: 40px;
    line-height: 40px;
    padding: 5px 10px;
    min-width: 900px;
    /* border-bottom: 1px solid var(--color-border-light); */
} 

.filter-editor-dropdown {
    float: left;
    height: 40px;
    line-height: 40px;
    width: 175px;
}


.filter-selector__control {
    height: 36px !important;
    min-height: 36px !important;
    max-height: 36px;
    border: none !important;
    border-radius: 20px !important;
    padding: 0 5px ;
    background-color: var(--color-background-input) !important;
    display: flex;
    align-items: center;
}

.filter-selector__control input {
    height: 22px !important;
    font-size: 16px !important;
}

.filter-selector__input {
    height: 22px !important;
    font-size: 16px !important;
    color: var(--color-text-light) !important;
}

.filter-selector__value-container {
    width: 100%;
}

.filter-selector__control:hover {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.filter-selector__control--is-focused {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.filter-selector__placeholder {
    color: var(--color-scrollbar) !important;
}

.filter-selector__indicator {
    opacity: 0.65;
    padding: 0 !important;
    width: 14px !important;
    height: 14px !important;
    margin-bottom: 6px !important;
}

.filter-selector__indicator-separator {
    background-color: transparent !important;
    display: none !important;
}

.filter-selector__single-value {
    height: 16px !important;
    line-height: 16px !important;
    font-size: 16px !important;
    color: var(--color-text) !important;
    margin-left: 0 !important;
    margin-right: 4px !important;
}

.filter-selector__single-value > div {
    height: 16px !important;
    line-height: 16px !important;
}

.filter-selector__value-container, .filter-selector__control input {
    box-shadow: none !important;
    border: none !important;
    margin: 0 !important;
}

.filter-selector__menu, .filter-selector__menu-list {
    border: none !important;
    font-size: 16px !important;
    text-align: left !important;
    min-width: 160px !important;
    background-color: var(--color-menu) !important;
}

.filter-selector__option {
    height: 28px !important;
    line-height: 28px !important;
    padding: 0 10px !important;
    overflow: hidden !important;
}

.filter-selector__option:hover, .filter-selector__option--is-focused {
    background-color: var(--color-border) !important;
    color: var(--color-text) !important;
}

.filter-selector__option--is-selected {
    background-color: var(--color-primary) !important;
    color: var(--color-text) !important;
}

.filter-selector__menu-list::-webkit-scrollbar {
    width: 6px;
}

.filter-selector__menu-list::-webkit-scrollbar-track {
    background: rgba(120,120,120,0.5);
}

.filter-selector__menu-list::-webkit-scrollbar-thumb {
    background: var(--color-scrollbar);
    border-radius: 3px;
    cursor: pointer; 
}

.filter-select-left {
    float: left;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.filter-select-edit {
    width: calc(100% - 40px) !important;
    margin: 0 !important;
    padding: 10px 13px !important;
    height: 16px !important;
}

.filter-selector-container{
    margin-left: 0;
    width: calc(100% - 15px);
    margin-top: 2px;
    position: relative;
}

.filter-delete-transaction {
    float: left;
    height: 40px;
    line-height: 40px;
    background-position: left;
    padding-left: 24px;
    /* width: 60px; */
}

.filter-main-container {
    overflow-y: scroll;
    height: 400px;
}