.action-menu-dropdown{
    background-color: var(--color-menu);
    box-shadow: 2px 2px 20px -2px rgba(0,0,0,0.25);
    border-radius: 10px;
    overflow-y: scroll;
    position: relative; 
    z-index: 600;
    max-height: 300px;
    padding: 6px 0; 
}

.action-menu-option{
    height: 36px;
    line-height: 36px;
    margin: 2px 0;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.action-menu-option:hover{
    background-color: var(--color-hover);
}