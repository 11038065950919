.automated-reports-subtitle {
    font-size: 20px !important;
    margin-top: 30px;
}

.automated-reports-table {
    padding-left: 0px !important;
    margin-left: 0 !important;
    /* display: flex; 
    justify-content: space-between; */
}

.automated-reports-overflow {
    color: var(--color-text-light);
}

.automated-reports-file {
    cursor: pointer;
    margin-right: 10px;
    border: solid 2px var(--color-border);
    border-radius: 15px;
    font-size: 12px;
    width: 85px;
    padding-left: 20px;
    color: var(--color-accent-45)
}

.automated-reports-loading {
    margin-left: 360px;
    margin-top: 50px;
}

.automated-reports-editing {
    grid-row-start: row-1-start;
    grid-row-end: row-2-start;
    grid-column-start: col-1;
    grid-column-end: break1;
}

.automated-reports-filter-selector-container{
    margin-left: 0;
    width: calc(100% - 15px);
    margin-top: 10px;
    position: relative;
}

.automated-reports-filter-selector__control {
    height: 43px !important;
    border: none !important;
    border-radius: 20px !important;
    padding: 0 5px ;
    background-color: var(--color-background-input) !important;
    display: flex;
    align-items: center;
    width: 180px;
}

.automated-reports-filter-selector__control input {
    height: 22px !important;
    font-size: 16px !important;
}

/* .automated-reports-filter-selector__input {
    height: 22px !important;
    font-size: 16px !important;
    color: var(--color-text-light) !important;
} */

.automated-reports-filter-selector__value-container {
    width: 100%;
}

.automated-reports-filter-selector__control:hover {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.automated-reports-filter-selector__control--is-focused {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    background-color: var(--color-background-input) !important;
}

.automated-reports-filter-selector__placeholder {
    color: var(--color-scrollbar) !important;
}

.automated-reports-filter-selector__indicator {
    opacity: 0.65;
    padding: 0 !important;
    width: 14px !important;
    height: 35px !important;
    margin-bottom: 6px !important;
}

.automated-reports-filter-selector__indicator-separator {
    background-color: transparent !important;
    display: none !important;
}

.automated-reports-filter-selector__single-value {
    height: 40px !important;
    line-height: 16px !important;
    font-size: 16px !important;
    color: var(--color-text) !important;
    margin-left: 0 !important;
    margin-right: 4px !important;
}

.automated-reports-filter-selector__single-value > div {
    height: 16px !important;
    line-height: 16px !important;
}

/* .automated-reports-filter-selector__value-container, .filter-selector__control input {
    box-shadow: none !important;
    border: none !important;
    margin: 0 !important;
} */

.automated-reports-filter-selector__menu, .filter-selector__menu-list {
    border: none !important;
    font-size: 16px !important;
    text-align: left !important;
    min-width: 160px !important;
    background-color: var(--color-menu) !important;
}

.automated-reports-filter-selector__option {
    height: 28px !important;
    line-height: 28px !important;
    padding: 0 10px !important;
    overflow: hidden !important;
}

.automated-reports-filter-selector__option:hover, .filter-selector__option--is-focused {
    background-color: var(--color-border) !important;
    color: var(--color-text) !important;
}

.automated-reports-filter-selector__option--is-selected {
    background-color: var(--color-primary) !important;
    color: var(--color-text) !important;
}

.automated-reports-filter-selector__menu-list::-webkit-scrollbar {
    width: 6px;
}

.automated-reports-filter-selector__menu-list::-webkit-scrollbar-track {
    background: rgba(120,120,120,0.5);
}

.automated-reports-filter-selector__menu-list::-webkit-scrollbar-thumb {
    background: var(--color-scrollbar);
    border-radius: 3px;
    cursor: pointer; 
}

.automated-reports-new {
    color: var(--color-primary-dark);
    font-size: 16px;
    min-width: 200px;
    margin-top: 50px;
    margin-left: 270px;
    transition: none;
}

.automated-reports-new:hover {
    color: var(--color-accent);
}

.multi-select::placeholder {
    color: var(--color-text-input-placeholder);
}

.automated-reports-header {
    margin-top: 0px !important;
    margin-bottom: 15px !important;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    line-height: 40px;
    font-size: 24px;
}