

.code-edit-confirm-button {
    position: absolute;
    right: 8px;
    top: 4px;
    width: 20px;
    height: 20px;
    padding: 6px;
    margin-left: 2px;
    border-radius: 16px;
    background-color: transparent;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
}

.code-edit-confirm-button:hover {
    background-color: rgb(240,240,240);
}