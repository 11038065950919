.plan-summary-plan-information{
    width: 100%; 
    height: 300px;
    margin-top: 20px;
    /* background-color: red;  */
    position: relative; 
    top: 0;
    left: 0; 
    margin-left: 20px;
    display: flex;
    gap: 80px;
}

.plan-summary-plan-information-details-container{
    /* background-color: green;  */
    min-width: 1000px;
}

.plan-summary-plan-information-accounting-status-container{
    /* background-color: yellow;  */
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.plan-summary-trust-accounting{
    width: 100%; 
}

.plan-summary-accounts{
    width: 100%;
}