.filter-container{
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 25px; 
    background-color: var(--color-background);
}

.filter-label{
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: right;
    background-size: 20px;
    padding-right: 25px;
    cursor: pointer;
    font-weight: 500;
}

.filter-label:hover{
    color: var(--color-accent);
}

.filter-clear-all{
    margin-right: auto; 
    padding-right: 25px; 
}

.filter-count{
    background-color: var(--color-accent); 
    width: 22px;
    height: 22px;
    border-radius: 10000px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; 
    font-weight: 700;
    margin-right: 8px;
    line-height: 22px;
}
