.transaction-error-menu{
    width: 28px;
    height: 38px; 
    background-image: url('/images/icons/alert.png');
    background-size: 20px; 
    background-repeat: no-repeat;
    background-position: center center; 
    position: relative; 
}

.transaction-error-container{
    position: absolute; 
    bottom: 100%; 
    right: 0; 
    width: 240px; 
    height: auto;
    background-color: var(--color-menu-raised); 
    z-index: 9999;
    border-radius: 10px;
    z-index: 99999;
    overflow: hidden;
    white-space: wrap;
    display: none;
}

.transaction-error-menu:hover .transaction-error-container{
    display: block;
}

.transaction-error-title{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
    line-height: 16px; 
    padding: 15px;
    padding-bottom: 0; 
}

.transaction-error-description{
    font-size: 12px;
    line-height: 16px;
    padding-left: 15px;
    padding-right: 15px; 
}

.transaction-error-action-container{
    margin-top: 8px; 
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative; 
    gap: 4px 30px; 
    width: 100%; 
    padding-bottom: 15px;
}

.transaction-error-action{
    display: flex;
    align-items: center;
}

.transaction-action-filter{
    position: relative; 
    background-color: var(--color-menu-raised); 
    min-width: 225px; 
    height: 36px; 
    font-size: 14px; 
    font-weight: 500;
    display: flex;
    align-items: center;
    padding-left: 15px; 
    font-size: 14px;
    font-weight: 500;
    margin-top: 11px; 
    cursor: pointer;
}

.transaction-action-accept{
    padding-left: 20px; 
    background-image: url('/images/icons/action-check.png');
    background-size: 16px; 
    background-repeat: no-repeat;
    background-position: center left;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-left: 15px;
}

.transaction-action-delete{
    padding-left: 20px; 
    background-image: url('/images/icons/delete.png');
    background-size: 16px; 
    background-repeat: no-repeat;
    background-position: center left;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-left: 15px; 
    color: var(--color-accent-dark); 
}

.transaction-action-filter:hover{
    background-color: var(--color-hover); 
}

.account-info{
    width: 100%; 
    height: 200px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    min-width: 1000px; 
}

.account-info-details{
    min-width: 600px;
    height: 100%; 
    display: grid;
    grid-template-columns: 
        [col1] 320px
        [break1] 20px
        [col2] 320px
        [break2] 20px;

    grid-template-rows: 
        [row1] 100px
        [row2] 100px
        [rowend]; 
    ;
}

.account-info-save{
    background-image: url('/images/icons/confirm.png');
    background-size: 16px; 
    background-repeat: no-repeat;
    background-position: left 6px; 
    margin-left: 20px;
    padding-left: 20px;
    font-size: 16px;
    line-height: 32px;
}   

.account-info-cancel{
    background-image: url('/images/icons/delete.png');
    background-size: 16px; 
    background-repeat: no-repeat;
    background-position: left 6px; 
    margin-left: 20px;
    padding-left: 20px;
    font-size: 16px;
    color: var(--color-accent-dark); 
    line-height: 32px;
}

.account-info-detail {
    position: relative; 
}

.account-info-detail-input{
    margin: 2px 0px 0px !important;
    padding: 2px 8px !important;
    height: 32px !important; 
}

.account-info-detail.provider{
    grid-row-start: row1;
    grid-row-end: row2;
    grid-column-start: col1;
    grid-column-end: break1;
}
.account-info-detail.number{
    grid-row-start: row1;
    grid-row-end: row2;
    grid-column-start: col2;
    grid-column-end: break2;
}

.account-info-detail.reviewed{
    position: absolute;
    top: 210px; 
    left: 20px; 
    z-index: 2;
}

.account-info-detail.reviewed > .account-info-checkbox{
    position: absolute; 
    top: 36px; 

}

.account-info-detail.participant{
    grid-row-start: row2;
    grid-row-end: rowend;
    grid-column-start: col1;
    grid-column-end: break1;
}
.account-info-detail.type{
    grid-row-start: row2;
    grid-row-end: rowend;
    grid-column-start: col2;
    grid-column-end: break2;
}


.account-info-notes{
    align-self: flex-start;
    min-width: 300px; 
    height: 165px;
    flex: 1; 
    position: relative;
    border-radius: 8px !important;
    overflow: hidden;
    margin-left: 40px; 
}

.account-info-notes > textarea{
    position: absolute; 
    top: 0; 
    left: 0;
    padding: 0; 
    margin: 0; 
    width: calc(100% - 20px);
    min-height: 145px !important;
    height: 145px !important; 
    resize: none;
    border: none !important;
    outline: none !important;
    border-radius: 0 !important;
    padding: 10px; 
}
.account-info-notes > textarea:hover{
    outline: none !important;
    border: none !important;
}
.account-info-notes > textarea:active{
    outline: none !important;
    border: none !important;
}
.account-info-notes > textarea:focus-visible{
    outline: none !important;
    border: none !important;
}