.sla-navigation-logo {
    position: fixed;
    left: 20px;
    top: 30px;
    font-family: 'Acre', sans-serif;
    font-weight: 800;
    line-height: 50px;
    font-size: 32px;
    letter-spacing: 0;
    cursor: pointer;
    color: white !important;
}

.sla-navigation-logo img {
    height: 48px;
    margin-right: 3px;
    float: left;
}

.sla-navigation-logo-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--color-primary);
    margin-left: 1px;
    margin-right: 1px;
    margin-bottom: 7px;
}

.sla {
    width: calc(100vw - 260px);
    padding: 38px 220px;
    padding-right: 40px;
    background-color: #151515;
}

.sla a, .sla * a {
    line-height: inherit !important;
}

.sla h1 {
    color: white !important;
    margin: 0;
    font-size: 32px;
    line-height: 36px;
    font-family: 'Acre', sans-serif !important;
    font-weight: 800 !important;
    text-transform: uppercase;
    margin-bottom: 60px;
}

.sla h2 {
    color: white !important;
    margin: 24px 0;
    margin-top: 40px;
    font-size: 24px;
    line-height: 26px;
    font-family: 'Acre', sans-serif !important;
    font-weight: 800 !important;
    text-transform: uppercase;
}

.sla h3 {
    color: white !important;
    margin: 8px 0;
    margin-top: 30px;
    font-size: 18px;
    line-height: 20px;
    font-family: 'Acre', sans-serif !important;
    font-weight: 800 !important;
    text-transform: uppercase;
}

.sla p {
    color: white;
    font-family: 'Ricardo', sans-serif !important;
    font-size: 16px;
    line-height: 22px !important;
    font-weight: 400 !important;
    margin-bottom: 20px;
}

.sla * strong {
    font-weight: 800 !important;
    line-height: 24px !important;
    color: var(--color-primary) !important;
}

.sla hr {
    border-color: var(--color-border) !important;
    margin: 40px 0 !important;
}

.sla ul {
    padding-left: 20px;
}

.sla ul li {
    line-height: 22px !important;
    margin-bottom: 12px !important;
}

.sla .sn {
    display: inline-block;
    width: 40px !important;
    color: var(--color-primary) !important;
    font-weight: 800 !important;
}

.sla-note {
    background-image: url(/images/icons/info-white.png);
    background-position: 20px 20px;
    background-repeat: no-repeat;
    background-size: 24px;
    background-color: var(--color-background);
    border: 1px solid var(--color-border);
    border-radius: 10px;
    padding: 20px;
    padding-left: 56px;
}

.sla-note h2 {
    margin-top: 0;
    margin-bottom: 10px;
}

.sla-cta .submit {
    float: left !important;
    margin-top: 20px !important;
}