.investments-metric {
    position: relative;
    float: left;
    width: calc(30% - 40px);
    padding: 20px;
}

.investments-status-donut {
    position: relative;
    float: left;
    width: calc(30% - 40px);
    margin: 20px;
    margin-bottom: 0;
    height: 130px;
}

.investments-metric-value {
    text-align: center;
    font-family: 'Acre', sans-serif;
    font-weight: 800;
    font-size: 80px;
    line-height: 80px;
    padding-top: 20px;
    height: 80px;
}

.investments-metric-loading {
    position: absolute;
    left: calc(50% - 20px);
    top: 5px;
    height: 40px !important;
    width: 40px !important;
}

.investments-metric-label {
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 16px;
    height: 16px !important;
}

.investments-chart {
    float: left;
    width: 100%;
    padding: 20px;
}

.investments-chart h3, .investments-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
}

.investments-title {
    padding-top: 20px;
    padding-left: 20px;
    max-width: 200px;
}

.investment-title-with-paginator {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.investments-chart-container {
    position: relative;
    width: 100%;
    height: 240px;
}

.investments-chart-loading {
    position: absolute;
    left: calc(50% - 20px);
    top: 100px;
    height: 40px !important;
    width: 40px !important;
}

.investments-status-donut-loading {
    position: absolute;
    left: calc(50% - 20px);
    top: 50px;
    height: 40px !important;
    width: 40px !important;
}

.investments-link {
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
    height: 16px;
}

.investments-link img {
    width: 16px;
    height: 16px;
    vertical-align: top;
    margin-top: -1px;
}

.investment-title-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between
}

.investment-automated-reports {
    font-size: 15px;
    display: flex; 
    margin-right: 55px !important; 
    cursor: pointer; 
    color: var(--color-primary-dark)
}

.investment-selector-container{
    margin-left: 0;
    width: calc(100% - -8px);
    position: relative;
    width: 0px;
    height: 0px;
    margin-left: 100px;
}

.investment-selector__control {
    border: none !important;
    font-size: 15px !important;
    text-align: center !important;
    min-width: 55px !important;
    background-color: var(--color-menu) !important;
    padding-top: 0 !important;
    padding-bottom: 0px !important;
    margin-top: 0 !important;
    border-radius: 11px !important;
    width: 55px !important;
    outline: none !important;
    box-shadow: none !important;
}

.investment-selector__menu, .investment-selector__menu-list {
    border: none !important;
    font-size: 15px !important;
    text-align: center !important;
    min-width: 55px !important;
    background-color: var(--color-menu) !important;
    padding-top: 0 !important;
    padding-bottom: 0px !important;
    margin-top: 0 !important;
    border-radius: 11px !important;
    width: 55px !important;
}

.investment-selector__option {
    height: 28px !important;
    line-height: 28px !important;
    padding: 0 2px !important;
    overflow: hidden !important;
    color: var(--color-primary-dark) !important;
}

.investment-selector__option:hover, .investment-selector__option--is-focused {
    background-color: var(--color-border) !important;
    color: var(--color-text) !important;
    color: var(--color-accent) !important;
    cursor: pointer;
}

.investment-selector__menu-list::-webkit-scrollbar {
    width: 0px;
}

.investment-selector__menu-list::-webkit-scrollbar-thumb {
    background: var(--color-scrollbar);
    border-radius: 3px;
    cursor: pointer; 
}

.investment-selector__indicator {
    opacity: 0.65;
    padding: 0 !important;
    width: 0px !important;
    height: 0px !important;
    margin-bottom: 0px !important;
}

.investment-selector__indicator-separator {
    background-color: transparent !important;
    display: none !important;
}
