.journal-to-transfer-container{
    width: 600px; 
    height: 150px; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    margin-top: 40px;
    margin-left: auto; 
    margin-right: auto; 
}

.journal-to-arrow{
    width: 100px; 
    height: 100px; 
    background-image: url('/images/icons/next-accent.png');
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
}


.journal-to-amount{
    text-align: center;
    font-family: 'Acre', sans-serif;
    font-weight: 800;
    font-size: 80px;
    line-height: 80px;
    height: 80px;
    overflow: hidden;
}

.journal-to-source{
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 16px;
    height: 16px!important;
}

.journal-to-move-amount{
    outline: none; 
    border: none; 
    width: 300px;
    height: 32px; 
    font-size: 24px; 
    font-weight: 800;
    text-align: center;
    background-color: transparent; 
    border-bottom: 4px solid var(--color-primary); 
    overflow: hidden;
}

.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.journal-to-target{
    width: 300px;
}

.journal-to-available-label{

}